import { useTokenDecoder } from '~/composables/useTokenDecoder'
import * as schemas from '~/schemas'

export default defineNuxtRouteMiddleware(async (to, _from) => {
  const data = useTokenDecoder(to.params?.token)
  if (!data?.caseRef && !data?.formId) {
    const schema = Object.values(schemas).find((schema) => schema.id === to.params?.type)

    if (!schema) return navigateTo('/')
  }
})
